import DataTable from "../components/DataTable";
import {useEffect, useState} from "react";
import EditModal from "../components/EditModal";
import Button from "@mui/material/Button";
import userService from "../services/Users";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const fields = [
        {name: 'name', label: 'Name'},
        {name: 'email', label: 'Email'},
        {name: 'password', label: 'Password', type: 'password', hide: true},
    ];

    const fetchUsers = () => {
        userService.listUsers()
            .then(response => {
                setUsers(response);
            })
            .catch(error => {
                console.error('Error when getting users:', error);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleUpdateUser = (updatedData) => {
        const id = updatedData.id;
        delete updatedData.id;
        delete updatedData.created_at;
        userService.updateUser(id, updatedData)
            .then(() => {
                fetchUsers();
            })
            .catch(error => {
                console.error('Error when updating user:', error);
            });
    };

    const handleDeleteUser = (id) => {
        userService.deleteUser(id)
            .then(() => {
                fetchUsers();
            })
            .catch(error => {
                console.error('Error when deleting user:', error);
            });
    };

    const handleAddUser = (newData) => {
        userService.registerUser(newData)
            .then(() => {
                fetchUsers();
                setOpenModal(false);
            })
            .catch(error => {
                console.error('Error when creating user:', error);
            });
    };

    const openAddModal = () => {
        setFormData({});  // Clear form data for new entry
        setOpenModal(true);
    };

    const handleSubmit = () => {
        if (formData.id) {
            handleUpdateUser(formData);
        } else {
            handleAddUser(formData);
        }
        setOpenModal(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={openAddModal} sx={{marginBottom: 5}}>
                Add New User
            </Button>
            <EditModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                formData={formData}
                setFormData={setFormData}
                fields={fields}
                handleSubmit={handleSubmit}
            />
            <DataTable data={users} fields={fields} updateItem={handleUpdateUser} deleteItem={handleDeleteUser}/>
        </div>
    );
};

export default Users;
