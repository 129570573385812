import config from "../utils/config";
import axiosInstance from "../utils/axiosInstance";
import axios from "axios";

const API_BASE_URL = `${config.apiBaseUrl}/users`;

const UserService = {
    listUsers: () => {
        return axiosInstance.get(API_BASE_URL)
            .then(response => response.data.users || [])
            .catch(error => {
                console.error("Error fetching domains:", error);
                throw error;
            });
    },

    registerUser: (userData) => {
        return axios.post(API_BASE_URL, userData)
            .then(response => response.data)
            .catch(error => {
                console.error("Error registering user:", error);
                throw error;
            });
    },

    updateUser: (id, userData) => {
        return axiosInstance.put(`${API_BASE_URL}/${id}`, userData)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error updating domain with id ${id}:`, error);
                throw error;
            });
    },

    deleteUser: (id) => {
        return axiosInstance.delete(`${API_BASE_URL}/${id}`)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error deleting domain with id ${id}:`, error);
                throw error;
            });
    }
};

export default UserService;
