import DataTable from "../components/DataTable";
import {useEffect, useState} from "react";
import EditModal from "../components/EditModal";
import Button from "@mui/material/Button";
import domainService from "../services/Domains";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import ruleService from "../services/Rules";

const Rules = () => {
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [domainRules, setDomainRules] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const fields = [
        {name: 'url', label: 'URL', placeholder: 'test.example.com'},
        {name: 'destination_url', label: 'Destination URL', placeholder: 'https://google.com'}
    ];

    const fetchDomains = () => {
        domainService.listDomains()
            .then(response => {
                setDomains(response);
                if (response.length > 0) {
                    setSelectedDomain(response[0].id);  // Select the first domain by default
                }
            })
            .catch(error => {
                console.error('Error when getting domains:', error);
            });
    };

    const fetchRules = (domainId) => {
        ruleService.listRules(domainId)
            .then(response => {
                setDomainRules(response);
            })
            .catch(error => {
                console.error('Error when getting domain rules:', error);
            });
    };

    useEffect(() => {
        fetchDomains();
    }, []);

    useEffect(() => {
        if (selectedDomain) {
            fetchRules(selectedDomain);
        }
    }, [selectedDomain]);

    const handleUpdateRule = (updatedData) => {
        const {id, ...data} = updatedData;
        delete data.version;
        ruleService.updateRule(id, data)
            .then(() => {
                fetchRules(selectedDomain);
            })
            .catch(error => {
                console.error('Error when updating domain rule:', error);
            });
    };

    const handleDeleteRule = (id) => {
        ruleService.deleteRule(id)
            .then(() => {
                fetchRules(selectedDomain);
            })
            .catch(error => {
                console.error('Error when deleting domain rule:', error);
            });
    };

    const handleAddRule = (newData) => {
        newData['domain_id'] = selectedDomain
        ruleService.createRule(newData)
            .then(() => {
                fetchRules(selectedDomain);
                setOpenModal(false);
            })
            .catch(error => {
                console.error('Error when creating domain rule:', error);
            });
    };

    const openAddModal = () => {
        setFormData({});  // Clear form data for new entry
        setOpenModal(true);
    };

    const handleSubmit = () => {
        if (formData.id) {
            handleUpdateRule(formData);
        } else {
            handleAddRule(formData);
        }
        setOpenModal(false);
    };

    const handleDomainChange = (event) => {
        setSelectedDomain(event.target.value);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={openAddModal} sx={{marginBottom: 5}}>
                Add New Rule
            </Button>
            <div>
                <FormControl sx={{minWidth: 200, marginBottom: 2}}>
                    <InputLabel id="domain-select-label">Select Domain</InputLabel>
                    <Select
                        labelId="domain-select-label"
                        value={selectedDomain}
                        onChange={handleDomainChange}
                        label="Select Domain"
                    >
                        {domains.map((domain) => (
                            <MenuItem key={domain.id} value={domain.id}>
                                {domain.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <EditModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                formData={formData}
                setFormData={setFormData}
                fields={fields}
                handleSubmit={handleSubmit}
            />
            <DataTable data={domainRules} fields={fields} updateItem={handleUpdateRule}
                       deleteItem={handleDeleteRule}/>
        </div>
    );
};

export default Rules;
