import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import './App.css';
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {MainListItems} from "./components/MenuListItems";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Domains from "./pages/Domains";
import Rules from "./pages/Rules";
import Users from "./pages/Users";
import Copyright from "./components/Copyright";
import SignIn from "./pages/SignIn";
import {AuthProvider, useAuth} from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Button from "@mui/material/Button";
import {useMediaQuery, useTheme} from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#54595F',
        },
        secondary: {
            main: '#19857b',
        },
        delete: {
            main: '#ff1744',
        },
        error: {
            main: '#ff1744',
        },
        background: {
            default: '#f4f4f4',
        }
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h5: {
            color: '#FDEA21 !important',
        }
    }
});

function MainContent() {
    const {logout, isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(!isMobile);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="absolute" open={open && !isMobile}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    {!isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    <Typography
                        component="h1"
                        variant="h5"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        PAGE RULES
                    </Typography>
                    {isAuthenticated && (
                        <Button color="inherit" onClick={handleLogout}>
                            Logout
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List component="nav">
                    {MainListItems}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(100vh - 180px)',
                                flexGrow: 1,
                                overflow: 'auto',
                            }}>
                                <Routes>
                                    <Route path="/" element={<SignIn/>}/>
                                    <Route path="/domains" element={<ProtectedRoute><Domains/></ProtectedRoute>}/>
                                    <Route path="/rules" element={<ProtectedRoute><Rules/></ProtectedRoute>}/>
                                    <Route path="/users" element={<ProtectedRoute><Users/></ProtectedRoute>}/>
                                </Routes>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{pt: 4}}/>
                </Container>
            </Box>
        </Box>
    );
}

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <ThemeProvider theme={defaultTheme}>
                    <MainContent/>
                </ThemeProvider>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
