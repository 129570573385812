import DataTable from "../components/DataTable";
import {useEffect, useState} from "react";
import EditModal from "../components/EditModal";
import Button from "@mui/material/Button";
import domainService from "../services/Domains";

const Domains = () => {
    const [domains, setDomains] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const fields = [{name: 'name', label: 'Name'}];

    const fetchDomains = () => {
        domainService.listDomains()
            .then(response => {
                setDomains(response);
            })
            .catch(error => {
                console.error('Error when getting domains:', error);
            });
    };

    useEffect(() => {
        fetchDomains();
    }, []);

    const handleUpdateDomain = (updatedData) => {
        const id = updatedData.id;
        delete updatedData.id;
        domainService.updateDomain(id, updatedData)
            .then(() => {
                fetchDomains();
            })
            .catch(error => {
                console.error('Error when updating domain:', error);
            });
    };

    const handleDeleteDomain = (id) => {
        domainService.deleteDomain(id)
            .then(() => {
                fetchDomains();
            })
            .catch(error => {
                console.error('Error when deleting domain:', error);
            });
    };

    const handleAddDomain = (newData) => {
        domainService.createDomain(newData)
            .then(() => {
                fetchDomains();
                setOpenModal(false);
            })
            .catch(error => {
                console.error('Error when creating domain:', error);
            });
    };

    const openAddModal = () => {
        setFormData({});  // Clear form data for new entry
        setOpenModal(true);
    };

    const handleSubmit = () => {
        if (formData.id) {
            handleUpdateDomain(formData);
        } else {
            handleAddDomain(formData);
        }
        setOpenModal(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={openAddModal} sx={{marginBottom: 5}}>
                Add New Domain
            </Button>
            <EditModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                formData={formData}
                setFormData={setFormData}
                fields={fields}
                handleSubmit={handleSubmit}
            />
            <DataTable data={domains} fields={fields} updateItem={handleUpdateDomain} deleteItem={handleDeleteDomain}/>
        </div>
    );
};

export default Domains;
