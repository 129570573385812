import {useEffect} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import {isTokenExpired} from '../utils/jwt';

const ProtectedRoute = ({children}) => {
    const {isAuthenticated, authToken, logout} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (authToken && isTokenExpired(authToken)) {
                logout();
                navigate('/'); // Redirect to SignIn page
            }
        };

        checkTokenExpiration();
        const interval = setInterval(checkTokenExpiration, 60000); // Check every 60 seconds

        return () => clearInterval(interval);
    }, [authToken, logout, navigate]);

    return isAuthenticated ? children : <Navigate to="/"/>;
};

export default ProtectedRoute;
