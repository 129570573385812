import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from './config';

const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
});

axiosInstance.interceptors.request.use(
    (request) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status >= 500;
    }
});

export default axiosInstance;
