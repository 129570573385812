import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import {useState} from 'react';
import EditModal from './EditModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DataTable = ({data, fields, updateItem, deleteItem}) => {
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const openEditModal = (item) => {
        setFormData(item);
        setOpenModal(true);
    };

    const handleSubmit = () => {
        updateItem(formData);
        setOpenModal(false);
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setOpenDialog(true);
    };

    const confirmDelete = () => {
        deleteItem(deleteId);
        setOpenDialog(false);
    };

    return (
        <div className="stickyTable">
            <Table size="small">
                <TableHead>
                    <TableRow className="stickyHeader">
                        {fields.filter((item) => !item.hide).map((item) => (
                            <TableCell key={'cell-' + item.name}>{item.label}</TableCell>
                        ))}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <TableRow key={'item-' + item.id}>
                            {fields.filter((item) => !item.hide).map((field) => (
                                <TableCell key={'data-' + item.id + '-' + field.name}>{item[field.name]}</TableCell>
                            ))}
                            <TableCell key={'actions-' + item.id}>
                                <EditIcon
                                    color="primary"
                                    onClick={() => openEditModal(item, 'domain')}
                                />
                                <ClearIcon
                                    color="error"
                                    onClick={() => handleDelete(item.id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <EditModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                formData={formData}
                setFormData={setFormData}
                fields={fields}
                handleSubmit={handleSubmit}
            />
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DataTable;
