import {Box, Button, Modal, TextField} from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditModal = ({open, handleClose, formData, setFormData, fields, handleSubmit}) => {
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-modal-title"
            aria-describedby="edit-modal-description"
        >
            <Box sx={style}>
                {fields.map(field => (
                    <TextField
                        placeholder={field.placeholder || ''}
                        key={field.name}
                        fullWidth
                        label={field.label}
                        type={field.type || 'text'}
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                ))}
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{mt: 3, mb: 2}}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        </Modal>
    );
};

export default EditModal;
