import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import PeopleIcon from '@mui/icons-material/People';
import RuleIcon from '@mui/icons-material/Rule';

export const MainListItems = (
    <React.Fragment>
        <ListItemButton component={RouterLink} to="/domains">
            <ListItemIcon>
                <DomainAddIcon/>
            </ListItemIcon>
            <ListItemText primary="Domains"/>
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/rules">
            <ListItemIcon>
                <RuleIcon/>
            </ListItemIcon>
            <ListItemText primary="Rules"/>
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/users">
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Users"/>
        </ListItemButton>
    </React.Fragment>
);
