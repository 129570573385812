import axios from 'axios';
import config from "../utils/config";

const API_BASE_URL = `${config.apiBaseUrl}/tokens/authentication`;

const TokenService = {
    createAuthenticationToken: (credentials) => {
        return axios.post(API_BASE_URL, credentials)
            .then(response => response.data)
            .catch(error => {
                console.error("Error creating authentication token:", error);
                throw error;
            });
    }
};

export default TokenService;
