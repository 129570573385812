import config from '../utils/config';
import axiosInstance from "../utils/axiosInstance";

const API_BASE_URL = `${config.apiBaseUrl}/domains`;

const DomainService = {
    listDomains: () => {
        return axiosInstance.get(API_BASE_URL)
            .then(response => response.data.domains || [])
            .catch(error => {
                console.error("Error fetching domains:", error);
                throw error;
            });
    },

    createDomain: (domainData) => {
        return axiosInstance.post(API_BASE_URL, domainData)
            .then(response => response.data)
            .catch(error => {
                console.error("Error creating domain:", error);
                throw error;
            });
    },

    updateDomain: (id, domainData) => {
        return axiosInstance.put(`${API_BASE_URL}/${id}`, domainData)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error updating domain with id ${id}:`, error);
                throw error;
            });
    },

    deleteDomain: (id) => {
        return axiosInstance.delete(`${API_BASE_URL}/${id}`)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error deleting domain with id ${id}:`, error);
                throw error;
            });
    }
};

export default DomainService;
