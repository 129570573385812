import config from '../utils/config';
import axiosInstance from "../utils/axiosInstance";

const API_BASE_URL = `${config.apiBaseUrl}/rules`;

const RuleService = {
    listRules: (domainId) => {
        return axiosInstance.get(`${API_BASE_URL}/${domainId}`)
            .then(response => response.data.rules || [])
            .catch(error => {
                console.error("Error fetching rules:", error);
                throw error;
            });
    },

    createRule: (ruleData) => {
        return axiosInstance.post(API_BASE_URL, ruleData)
            .then(response => response.data)
            .catch(error => {
                console.error("Error creating rule:", error);
                throw error;
            });
    },

    updateRule: (id, ruleData) => {
        return axiosInstance.put(`${API_BASE_URL}/${id}`, ruleData)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error updating rule with id ${id}:`, error);
                throw error;
            });
    },

    deleteRule: (id) => {
        return axiosInstance.delete(`${API_BASE_URL}/${id}`)
            .then(response => response.data)
            .catch(error => {
                console.error(`Error deleting rule with id ${id}:`, error);
                throw error;
            });
    }
};

export default RuleService;
