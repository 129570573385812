import {createContext, useContext, useEffect, useState} from 'react';
import {isTokenExpired} from '../utils/jwt';

const AuthContext = createContext(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token && !isTokenExpired(token)) {
            setIsAuthenticated(true);
            setAuthToken(token);
        } else {
            localStorage.removeItem('authToken');
            setIsAuthenticated(false);
            setAuthToken(null);
        }
    }, []);

    const login = (token) => {
        if (!isTokenExpired(token)) {
            localStorage.setItem('authToken', token);
            setIsAuthenticated(true);
            setAuthToken(token);
        } else {
            console.error("Attempted to login with expired token");
        }
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
        setAuthToken(null);
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, authToken}}>
            {children}
        </AuthContext.Provider>
    );
};
